<template>
  <div>
    <a-modal
      wrap-class-name="password-forget"
      :visible="sendVisible"
      :mask-closable="false"
      :title="$t('pages_login_004')"
      :confirm-loading="loading"
      :ok-text="$t('pages_login_009')"
      @ok="sendMessage('')"
      @cancel="$emit('update:sendVisible', false)"
    >
      <div class="title flex-align">
        <info-circle-filled class="ic-tip" />
        <span>{{ $t('pages_register_024') }}</span>
      </div>
      <a-form ref="editRef" layout="vertical" :model="editForm" :rules="editRules">
        <a-form-item name="email" :label="$t('pages_register_002')">
          <a-input v-model:value="editForm.email" :placeholder="$t('pages_register_003')" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      wrap-class-name="email-send-success"
      :visible="succVisible"
      :mask-closable="false"
      @cancel="$emit('update:succVisible', false)"
    >
      <div class="apply-result">
        <div class="title flex-align">
          <check-circle-outlined class="ic-success" />
          <span>{{ $t('pages_register_026') }}</span>
        </div>
        <a-alert style="margin-top: 24px" :message="`${$t('pages_register_027')} ${editForm.email}`" banner />
        <div class="desc">
          <div>{{ $t('pages_register_029') }}</div>
          <a-badge style="margin-top: 12px" status="processing" :text="$t('pages_register_030')" />
          <a-badge style="margin-top: 5px" status="processing" :text="$t('pages_register_031')" />
          <div style="margin-top: 18px">{{ $t('pages_register_032') }}<span class="link-line" @click="sendMessage($t('pages_register_034'))">{{ $t('pages_register_033') }}</span></div>
        </div>
      </div>
      <template #footer>
        <a-button
          type="primary"
          @click="$emit('update:succVisible', false)"
        >{{ $t('common_text_024') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { _login } from '@/api'
import i18n from '@/locale'
export default {
  name: 'PasswordForget',
  props: {
    sendVisible: {
      type: Boolean,
      default: () => { return false }
    },
    succVisible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props, ctx) {
    const editRef = ref(null)
    const state = reactive({
      loading: false,
      editForm: {
        email: ''
      },
      editRules: {
        email: [
          { required: true, max: 255 },
          { pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: i18n.global.t('pages_register_025') }
        ]
      }
    })

    const sendMessage = (sendMessage) => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          return _login.resetPwdMail({ email: state.editForm.email })
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            ctx.emit('update:sendVisible', false)
            ctx.emit('update:succVisible', true)
            if (sendMessage) message.success(sendMessage)
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    return {
      editRef,
      sendMessage,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.password-forget {
  .title {
    margin: -8px 0 24px 0;
    font-weight: 500;
    .ic-tip {
      color: @main-color;
      font-size: 18px;
      margin-right: 8px;
    }
  }
}
.email-send-success {
  .apply-result {
    margin-top: 30px;
    .title {
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      .ic-success {
        color: #02A900;
        margin-right: 8px;
        font-size: 28px;
      }
    }
    .desc {
      margin-top: 24px;
      .link-line {
        color: @main-color;
        padding: 0 2px;
        cursor: pointer;
      }
    }
  }
}
</style>

