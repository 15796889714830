<template>
  <div id="login">
    <a-layout class="layout-style">
      <a-layout-content>
        <!-- <a-row align="middle">
          <img :src="themeData.darkLogoList" class="logo">
        </a-row> -->
        <a-row style="margin-top: 0px; height: 100%">
          <div class="login-swiper flex-align">
            <img src="~@/assets/images/login-bg.jpg">
          </div>
          <div class="card-login">
            <a-row justify="end" align="middle" class="lang-selector">
              <GlobalOutlined class="ic-global" />
              <a-dropdown :trigger="['click']">
                <a class="main-text-color" @click.prevent>
                  {{ $i18n.locale === 'en' ? 'English' : '中文' }}
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu @click="languageChange">
                    <a-menu-item key="zh" :disabled="$i18n.locale === 'zh'">
                      <a>中文</a>
                    </a-menu-item>
                    <a-menu-item key="en" :disabled="$i18n.locale === 'en'">
                      <a>English</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-row>
            <div class="card-body">
              <h1>{{ $t('pages_login_010') }}</h1>
              <a-form
                :model="loginState"
                :rules="loginRules"
                class="login-form ant-round-form login-form"
                @finish="loginSuccess"
              >
                <a-form-item name="username">
                  <div style="position: relative">
                    <a-input
                      v-model:value="loginState.username"
                      style="position: relative"
                      :placeholder="$t('pages_login_002')"
                    />
                    <!-- <a-form-item-rest>
                      <a-select
                        v-model:value="loginState.areacode"
                        :bordered="false"
                        class="area-select"
                      >
                        <a-select-option value="+86">+86</a-select-option>
                        <a-select-option value="+87">+87</a-select-option>
                      </a-select>
                    </a-form-item-rest> -->
                  </div>
                </a-form-item>

                <a-form-item name="password">
                  <a-input-password
                    v-model:value="loginState.password"
                    :placeholder="$t('pages_login_003')"
                  />
                </a-form-item>

                <div v-if="resMsg" class="error-text">{{ resMsg }}</div>

                <a-form-item v-if="needsCode" name="code">
                  <div class="flex-align">
                    <a-input
                      v-model:value="loginState.code"
                      style="border-radius: 3px"
                      :placeholder="$t('pages_login_008')"
                    />
                    <img class="code-src" :src="codeSrc" @click="loadValidateCode">
                  </div>
                </a-form-item>

                <div class="login-form-forgot"><span @click="() => sendVisible = true">{{ $t('pages_login_004') }}</span></div>

                <a-form-item style="margin-top: 64px">
                  <a-button
                    style="width: 100%"
                    shape="round"
                    type="primary"
                    html-type="submit"
                    :loading="loading"
                  >{{ $t('pages_login_005') }}</a-button>
                </a-form-item>
                <!-- <a-form-item style="text-align: center; margin-top: -18px;  font-size: 12px">
                  <span style="color: #999">{{ $t('pages_login_006') }}</span>
                  <span class="main-text-color" style="cursor: pointer" @click="$router.push('/register')">{{ $t('pages_login_007') }}</span>
                </a-form-item> -->
              </a-form>
            </div>
            <div class="keep-record">
              <div v-if="themeData.address">{{ themeData.address }}</div>
              <a target="_blank" href="https://beian.miit.gov.cn">{{ themeData.copyRight }}</a>
            </div>
          </div>
        </a-row>
      </a-layout-content>
    </a-layout>
    <!-- <div class="keep-record">
      <div v-if="themeData.address">{{ themeData.address }}</div>
      <a target="_blank" href="https://beian.miit.gov.cn">{{ themeData.copyRight }}</a>
    </div> -->
    <password-forget
      v-model:send-visible="sendVisible"
      v-model:succ-visible="succVisible"
    />
  </div>
</template>
<script>
import { ref, reactive, inject, defineComponent } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import i18n from '@/locale'
import PasswordForget from './components/password-forget'
import { _login } from '@/api'
import { getIdentyImg } from '@/util'
export default defineComponent({
  name: 'Login',
  components: {
    'password-forget': PasswordForget
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const resMsg = ref('')
    const needsCode = ref(false)
    const codeSrc = ref('')
    const sendVisible = ref(false)
    const succVisible = ref(false)
    const loading = ref(false)

    const themeData = inject('$themeData')

    const loginState = reactive({
      areacode: '+86',
      username: '',
      password: '',
      code: ''
    })

    const loginRules = {
      username: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_002') }],
      password: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_003') }],
      code: [{ required: true, trigger: 'change', message: i18n.global.t('pages_login_008') }]
    }

    const languageChange = async (e) => {
      localStorage.setItem('locale-language', e.key)
      await store.dispatch('getLanguage')
      location.reload()
    }

    const loginSuccess = async () => {
      resMsg.value = ''
      loading.value = true
      const { username, password, code } = loginState
      try {
        const res = await _login.login({
          userName: username,
          password,
          code
        })
        if (res.data.succ) {
          succDeal(res.data.data)
        } else {
          loading.value = false
          resMsg.value = res.data.resMsg
          if (['010001', '010006'].includes(res.data.resCode)) {
            needsCode.value = true
            loadValidateCode()
          }
        }
      } catch (e) {
        console.log(e)
        loading.value = false
      }
    }

    const loadValidateCode = () => {
      loginState.code = ''
      codeSrc.value = getIdentyImg()
    }

    const succDeal = async (data) => {
      const { menuList, userInfo, status, authFlag, activityStatus } = data
      if (authFlag) {
        userInfo.status = status
        store.commit('updateMenuList', menuList)
        store.commit('updateUserInfo', userInfo)
        if (userInfo.mobile) {
          localStorage.setItem('mobileStatus', true)
          router.push('/home/index')
        } else {
          router.push('/user/account_materials')
        }
        await store.dispatch('getLanguage')
        loading.value = false
      } else {
        if (activityStatus === '40') {
          router.push('/guide_success')
        }
      }
    }

    const init = () => {
      if (route.query.source) {
        _login.loginCheck()
          .then(res => {
            if (res.data.succ) succDeal(res.data.data)
          }).catch(err => console.log(err))
      }
    }

    init()

    return {
      themeData,
      resMsg,
      needsCode,
      codeSrc,
      loading,
      sendVisible,
      succVisible,
      loginState,
      loginRules,
      loginSuccess,
      languageChange,
      loadValidateCode
    }
  }
})
</script>
<style lang="less" scoped>
#login {
  .scrollbar-none;
  background: #fff;
  height: 100vh;
  overflow-y: scroll;
  .ic-global {
    color: @main-color;
  };
  .logo {
    width: 180px;
  };
  .login-swiper {
    flex: 0.7;
    height: 100%;
    //background-image: url('/assets/images/login-bg.jpg');
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .card-login {
    flex: 1;
    text-align: left;
    margin-left: 49px;
    background: #fff;
    padding: 0px 63px 0 44px;
    position: relative;
    .lang-selector {
      padding-top:30px;
      padding-bottom: 150px;
    }
    .card-body {
      //margin: 30px 0 0 37px;
      display: flex; /* 启用flex布局 */
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中，如果需要 */
      padding: 0 150px; /* 假设左右两边各留50px的空白 */
      margin: auto; /* 自动调整边距以实现居中 */
      flex-direction: column; /* 子元素垂直排列，如果需要 */
      .login-form {
        width: 100%;
      }
      .error-text {
        color: @error-color;
        margin: -18px 0 12px 0;
      }
      .login-form-forgot {
        font-size: 12px;
        font-weight: 500;
        color: @main-color;
        text-align: right;
        margin: -12px 0 24px 0;
        span {
          cursor: pointer;
        }
      }
      .code-src {
        margin: 0 18px 0 36px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .keep-record {
    text-align: center;
    color: #9c9d9c;
    padding-top: 128px;
    a {
      color: #9c9d9c;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
#login::-webkit-scrollbar {
  display: none;
}
</style>
